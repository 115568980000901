import { STACK_TITLE } from "@/constants";
import { getInitialColor, getRandomColor } from "@/functions/randomColor";
import { useEffect, useState } from "react";

export default function TypeAnimationText() {
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [gradient, setGradient] = useState(getInitialColor());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % STACK_TITLE.length);
      setGradient(getRandomColor());
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-[100vw] relative left-[50%] translate-x-[-50%] text-center px-4">
      {STACK_TITLE.map((title, index) => (
        <div
          key={index}
          className={currentTitleIndex === index ? "animate-typing" : "hidden"}
        >
          <span
            className={`text-data block m-auto ${gradient.text} -skew-x-12 text-[40px]/[50px] md:text-6xl lg:text-[100px] font-bold lg:leading-normal`}
          >
            {title}
          </span>
        </div>
      ))}
    </div>
  );
}
