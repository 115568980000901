import vectorPurple from "@/assets/shared/purple.png";
import vectorGreen from "@/assets/shared/green.png";
import vectorGreen1 from "@/assets/shared/green1.png";
import vectorPink from "@/assets/shared/pink.png";
import vectorPink1 from "@/assets/shared/pink1.png";
import vectorYellow from "@/assets/shared/yellow.png";
import vectorYellow1 from "@/assets/shared/yellow1.png";
import vectorBlue from "@/assets/shared/blue.png";
import vectorBlue1 from "@/assets/shared/blue1.png";
import vectorOrange from "@/assets/shared/orange.png";
import vectorOrange1 from "@/assets/shared/orange1.png";
import vectorPink2 from "@/assets/shared/pink2.png";

const colors = [
  { color: "bg-green", src: vectorGreen, text: "bg-gradientGreen" },
  { color: "bg-pink", src: vectorPink, text: "bg-gradientPurple" },
  { color: "bg-blue", src: vectorBlue, text: "bg-gradientLightBlueText" },
  { color: "bg-orange", src: vectorOrange, text: "bg-gradientOrange" },
  { color: "bg-yellow", src: vectorYellow, text: "bg-gradientYellow" },
  { color: "bg-red", src: vectorPink1, text: "bg-gradientRed" },
  { color: "bg-orange", src: vectorOrange1, text: "bg-gradientOrange" },
  { color: "bg-yellow", src: vectorYellow1, text: "bg-gradientYellow" },
  { color: "bg-pink", src: vectorPink2, text: "bg-gradientTextPurple" },
  { color: "bg-blue", src: vectorBlue1, text: "bg-gradientLightBlueText" },
  { color: "bg-green", src: vectorGreen1, text: "bg-gradientGreen" },
  { color: "bg-pink", src: vectorPurple, text: "bg-gradientPurple" },
];

export const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

export const getInitialColor = () => {
  return colors[0];
};

export default getRandomColor;
